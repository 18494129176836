import FeddbackStatus from "../enums/ConsigneStatus";
import FeedbackServices from "../enums/FeedbackServices";
import FeddbackEvent from "./ConsigneEvent";
import Customers from "./Customers";
import Reservation from "./Reservation";
import ServicesItems from "./ServicesItems";
import Status from "./Status";

export default class Feedback {
    public id: number = 0;
    public idFeedback: string = '';   
    public idReservation: string = '';      
    public title: string = '';     
    public commerntarie: string = '';    
    // public status: FeddbackStatus = FeddbackStatus.None;
    // public service: FeedbackServices = FeedbackServices.None;
    public timestamp: number = 0;
    public events: Array<FeddbackEvent> = [];
    public statusClient: string = '';
    public solution: string = '';    
    public customerId: string = ''; 
    public idStatus: string = ''; 
    public idServicesItems: string = ''; 
    public customers: Customers = new Customers();
    public reservations: Reservation = new Reservation();
    public servicesItems: ServicesItems = new ServicesItems();
    public status: Status = new Status();
    public createDate: any = new Date();
    public updateDate: any = new Date();
    public annulled: string = '';
    public reasonAnnulled: string = ''; 
    

    constructor(
        title: string = '',           
        // status: FeddbackStatus = FeddbackStatus.None,
        // service: FeedbackServices = FeedbackServices.None,
        timestamp: number = 0,
        events: Array<FeddbackEvent> = [],
        id: number = 0,
        idFeedback: string = '',
        idReservation: string = '',
        commerntarie: string = '',
        statusClient: string = '',
        solution: string = '',
        customerId: string = '',   
        idStatus: string = '',   
        idServicesItems: string = '',   
        customers: Customers = new Customers(),
        reservations: Reservation = new Reservation(),
        servicesItems: ServicesItems = new ServicesItems(),
        status: Status = new Status(),
        createDate: any = '',
        updateDate: any = '',
        annulled: string = '',
        reasonAnnulled: string = '', 
    ) {
        this.id = id;
        this.idFeedback = idFeedback;
        this.idReservation = idReservation;
        this.title = title;               
        this.status = status;
        this.idServicesItems = idServicesItems;
        this.timestamp = timestamp;
        this.events = events;      
        this.commerntarie = commerntarie;        
        this.statusClient = statusClient; 
        this.solution = solution; 
        this.customerId = customerId;
        this.idStatus = idStatus;
        this.customers = customers;    
        this.reservations = reservations;
        this.servicesItems = servicesItems;    
        this.createDate = createDate;
        this.updateDate = updateDate;
        this.annulled = annulled;
        this.reasonAnnulled = reasonAnnulled; 
    }
}