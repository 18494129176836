export default class ServicesItems {
    public idServicesItems: number = 0;
    public servicesName: string = '';    

    constructor(
        idServicesItems: number = 0,
        servicesName: string = '',
       
    ) {
        this.idServicesItems = idServicesItems;
        this.servicesName = servicesName;
    }
}