import ClienteHeader from '../../shared/ClienteHeader/ClienteHeader';
import ProfilClientsRistorantDetailsTable from './ProfilClientsDetailsTable/ProfilClientsRistorantDetailsTable';
import './ProfilClientsDetails.css';

function ProfilClientsRistorantDetails() { 
  return (
    <div className="">
      <ClienteHeader src={""} title="Fiche Client Restaurant" />
      <ProfilClientsRistorantDetailsTable/>
    </div>
  );
}
export default ProfilClientsRistorantDetails;