import { useEffect, useRef, useState } from 'react';
import GnzDrawer from '../../shared/gnz-drawer/GnzDrawer';
import axios from 'axios';
import { API_MEWS_URL }   from '../../../ConfigUrl';
import { useNavigate } from 'react-router-dom';
import Feedback from '../../../models/Feedback';
import GnzButton from '../../shared/gnz-button/GnzButton';
import AllergiesCustomer from '../../../models/AllergiesCustomer';
import FoodPreferencesCustomer from '../../../models/FoodPreferencesCustomer';
import ProfilClientsPreferenceForm from '../../ProfilClients/ProfilClientPreferences/ProfilClientPreferencesForm/ProfilClientsPreferenceForm'
import { format } from 'date-fns';
import './FeedbackHistory.css';

interface IFeedbackHistory {
  customerId: string,
}

function FeedbackHistory(props: IFeedbackHistory) {
  const navigate = useNavigate();
  const drawerPreferences = useRef<HTMLDivElement>(null); 
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const [Feedback, SetFeedback] = useState<Array<Feedback>>([]);
  const [allergiesCustomer, AllergiesCustomer] = useState<Array<AllergiesCustomer>>([]);
  const [foodPreferencesCustomer, FoodPreferencesCustomer] = useState<Array<FoodPreferencesCustomer>>([]);  
  const Url = API_MEWS_URL;
  
  useEffect(() => {
    var customerId = props.customerId;
    if(customerId != ""){
      axios.get(Url + 'Feedback/' + customerId)
      .then(res => SetFeedback(res.data));
    }    
  }, [])

  useEffect(() => {
    axios.get(Url + 'AllergiesCustomer/' + props.customerId)
      .then(res => AllergiesCustomer(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'FoodPreferenceCustomer/' + props.customerId)
      .then(res => FoodPreferencesCustomer(res.data));
  }, [])

  const ClientPreferences = () => {
    openDrawerPreferences();
  };

  const openDrawerPreferences = () => {
    const drawerRefEl: any = drawerPreferences.current;
    drawerRefEl.open();
  }

  const closePreferencesEdit = () => {
    const drawerRefEl: any = drawerPreferences.current;
    drawerRefEl.close();
  }

  const handleChange = (event: any) => {
    // SetFeedback([]);
    // axios.get(Url + 'Feedback/' + startDate + '/' + event.target.value)
    //   .then(res => SetFeedback(res.data));
  };    

  return (
    <div className='userPreference'>
      <h3 className='userPreference_header'>Fiche Client</h3>
      <div className='userPreference_content'>
      <div className="ProfilClientsPreferenceTopActions">
        <select className='selectSyle'>
          <option value={5}>Profil</option>
          <option value={4} onClick={() => navigate(`/FeedbackClient`)} >Feedback</option>
          <option value={1} onClick={() => navigate(`/clientsPreferences`)} >Préférences</option>         
          <option value={3}>Email</option>
        </select>

        <div className='PreferenceSelectSyleDiv'>
          <GnzButton  label="Partager ficher client" onClick={ClientPreferences} color="#ffffff" backgroundColor="#1D62AF" />
        </div>
        <div className='selectTimeDiv'>
          <input type="date" className="Datasyle" defaultValue={date} onChange={(e) => setStartDate(e.target.value)} />
          <input type="date" className="Datasyle" defaultValue={date} onChange={handleChange} />
        </div>
        </div>

        <div className="userPreference_info">
        <div className="userPreference_Items">
            <h2><strong>Historiques</strong>:</h2>
            {Feedback.map((r, index) => (
              <div>
                <p>Feedback le {format(r.createDate, 'dd/MM/yyyy')} à {format(r.createDate, 'hh:mm:ss')} 
                  service {r.servicesItems?.servicesName}              
                </p>
                <p>{r.commerntarie}</p>
                <p>{r.status?.statusName} le {format(r.updateDate, 'dd/MM/yyyy')} à {format(r.updateDate, 'hh:mm:ss')}</p>
                <p>{r.solution}</p>
                </div>
            ))}
          </div>
        </div> 

        <GnzDrawer ref={drawerPreferences} component={
          <ProfilClientsPreferenceForm
            myParam={props.customerId}
            allergiesCustomer={allergiesCustomer}
            foodPreferencesCustomer={foodPreferencesCustomer}
            closeForm={closePreferencesEdit}
          />
        } />      
      </div>
    </div>
  );
}

export default FeedbackHistory