import CustomerAddresss from "./CustomerAddresss";

export default class Customer {
    public customersId: string = '';
    public sex: string = '';
    public number: string = '';
    public bedroom: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public classificationName: string = '';
    public accompanist: string = '';
    public nationality: string = '';
    public arrivalDate: string = '';
    public departureDate: string = '';
    public createdUtc: string = '';
    public departureTime: string = '';
    public customerAddresss: CustomerAddresss = new CustomerAddresss();  
    public birthDate: string = '';
    public postalCode: string = '';
    public nationalityCode: string = '';
    public city: string = '';
    public phone: string = '';
    public phone2: string = '';
    public email: string = '';
    public email2: string = '';  
    public languageCode: string = '';
    public importantDate: string = '';
    public arrivalTime: string = '';
    public vipCode: string = '';
    public isPms: string = '';
    public isCreditcard: string = '';
    public isSendMailCreditCard: string = '';
    public isReminder: string = '';

    constructor(
        customersId: string = '',
        sex: string = '',
        number: string = '',
        bedroom: string = '',
        firstName: string = '',
        lastName: string = '',
        classificationName: string = '',
        accompanist: string = '',
        nationality: string = '',
        arrivalDate: string = '',
        departureDate: string = '',
        createdUtc: string = '',
        departureTime: string = '',
        customerAddresss: CustomerAddresss = new CustomerAddresss(),       
        birthDate: string = '',
        postalCode: string = '',
        nationalityCode: string = '',
        city: string = '',
        phone: string = '',
        phone2: string = '',
        email: string = '',
        email2: string = '',       
        languageCode: string = '',
        importantDate: string = '',        
        arrivalTime: string = '',       
        vipCode: string = '', 
        isPms: string = '',     
        isCreditcard: string = '',     
        isSendMailCreditCard: string = '',     
        isReminder: string = '',       

        
    ) {
        this.customersId = customersId;
        this.sex = sex;
        this.number = number;
        this.bedroom = bedroom;
        this.firstName = firstName;
        this.lastName = lastName;   
        this.classificationName = classificationName;  
        this.accompanist = accompanist;   
        this.nationality = nationality;  
        this.arrivalDate = arrivalDate;
        this.departureDate = departureDate;
        this.createdUtc = createdUtc;  
        this.departureTime = departureTime;   
        this.customerAddresss = customerAddresss;
        this.birthDate = birthDate;
        this.customerAddresss = customerAddresss;
        this.postalCode = postalCode;
        this.nationalityCode = nationalityCode;
        this.city = city;
        this.phone = phone;
        this.phone2 = phone2;
        this.email = email;
        this.email2 = email2;
        this.languageCode = languageCode;
        this.importantDate = importantDate;
        this.arrivalTime = arrivalTime;
        this.vipCode = vipCode;
        this.isPms = isPms;
        this.isCreditcard = isCreditcard;
        this.isSendMailCreditCard = isSendMailCreditCard;
        this.isReminder = isReminder;
        
    }
}