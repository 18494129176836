import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import GnzButton from '../shared/gnz-button/GnzButton';
import FormMode from '../../enums/FormMode';
import GnzImage from '../shared/gnz-image/GnzImage';
import plus2 from '../../images/shared/plus2.png';
import more from '../../images/shared/more.png';
import FeedbackForm from './FeedbackForm/FeedbackForm';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import FeedbackHistory from './FeedbackHistory/FeedbackHistory';
import { Menu, MenuItem } from '@mui/material';
import ConsigneStatus from '../../enums/ConsigneStatus';
import GnzDialog from '../shared/gnz-dialog/GnzDialog';
import CancelFeedbackForm from './CancelFeedbackForm/CancelFeedbackForm';
import ConsigneFilter from '../../enums/ConsigneFilter';
import _Feedback from '../../models/Feedback';
import GnzDrawer from '../shared/gnz-drawer/GnzDrawer';
import ConsigneEvent from '../../models/ConsigneEvent';
import ConsigneEventType from '../../enums/ConsigneEventType';
import { useSearchParams, useNavigate } from 'react-router-dom';


import './Feedback.css';

function FeedbackClient() {
  const {userName} = useSelector((state: RootState) => state.user);
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0,10);

  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const { currentDate } = useSelector((state: RootState) => state.app);
  const drawerRef = useRef<HTMLDivElement>(null);
  const cancelDialogRef = useRef<HTMLDivElement>(null);
  const [mode, setMode] = useState<FormMode>(FormMode.None);
  const [filter, setFilter] = useState<ConsigneFilter>(ConsigneFilter.None);
  const [feedbackNew, setFeedbackNew] = useState<_Feedback>(new _Feedback());
  const myParam = localStorage.getItem('idCustomer');
  
 
  const newFeedback = () => {
    setMode(FormMode.New);
    setFeedbackNew(new _Feedback());
    openDrawer();
  };

  const openDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.open();
  }

  const closeDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.close();
  }

  const feedbackFormTitle = () => {
    switch (mode) {
      case FormMode.New:
        return 'Nouveau Feedback';
      case FormMode.Edit:
        return 'Edition de la feedback';
      default:
        return '';
    }
  }

  const handleChange = (event:any) => {     
    // axios.get(Url +'Reservation/' + startDate +'/' + event.target.value)
    // .then(res => reservation(res.data));            
  }; 
  
  return (
    <div className="feed">
      <div className="FeedbackTopActions">
        <h3 className='FeedbackTopActionsh3'>Feedback Clients</h3>

        <div className='FeedbackSelectSyleDiv'>
          {/* <input className="FeedbackDatasyle" placeholder='Du'></input>
          <input className="FeedbackDatasyle" placeholder='Au'></input> */}
          <input type="date" className="DatasyleFeddback" defaultValue={date} onChange={(e) => setStartDate(e.target.value)} />
          <input type="date" className="DatasyleFeddback"  defaultValue={date} onChange={handleChange} />
         
          <select className='selectSyleFeed'>
            <option value={1}>Filter</option>
            <option value={2}>.....</option>
            <option value={3}>.....</option>
          </select>
         
          <button>Rechercher</button>
          <GnzButton leftIcon={<GnzImage src={plus2} />} label="Nouveau Feedback" onClick={newFeedback} 
          color="#1D62AF" backgroundColor="#A0E295" />
        </div>
      </div>

      <FeedbackHistory customerId={myParam ?? ""} />     
     
      <GnzDrawer ref={drawerRef} component={
        <FeedbackForm
          mode={mode}
          feedback={undefined}
          title={feedbackFormTitle()}
          closeForm={closeDrawer}
          customerId={myParam ?? ""}
        />
      } />     
    </div>
  );
}
export default FeedbackClient;