import { useEffect, useState } from 'react';
import Feedback from '../../../models/Feedback';
import './FeedbackForm.css';
import Status from '../../../models/Status';
import ServicesItems from '../../../models/ServicesItems';
import StatusClient from '../../../models/StatusClient';
import FormMode from '../../../enums/FormMode';
import axios from 'axios';
import { API_MEWS_URL }   from '../../../ConfigUrl';
import Reservation from '../../../models/Reservation';
import Customer from '../../../models/Customers';

interface IFeedbackForm {
  mode: FormMode,
  feedback?: Feedback,
  title: string,
  closeForm: () => void,
  customerId: string,
}

function FeedbackForm(props: IFeedbackForm) {
  const [id, setId] = useState(0);
  const [comment, setComment] = useState('');
  const [resolution, setResolution] = useState('');
  const [status, setStatus] = useState('');
  const [customerId, setCustomerReservation] = useState('');
  const [idFeedback, setIdFeedback] = useState('');
  const [idReservation, setidReservation] = useState('');
  const [ReservationCustomer, setReservationCustomer] = useState<Array<Reservation>>([]);
  const [Customer, setCustomer] = useState<Customer>();
  const [service, setservice] = useState('');
  const [statusImg, setStatusImg] = useState('');
  const [StatusFilter, SetStatusFilter] = useState<Array<Status>>([]);
  const [ServicesItemsFilter, SetServicesItems] = useState<Array<ServicesItems>>([]);
  const [StatusClientFilter, StatusClient] = useState<Array<StatusClient>>([]);
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const Url = API_MEWS_URL;


  useEffect(() => {
    axios.get(Url + 'Status')
      .then(res => SetStatusFilter(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'ServicesItems')
      .then(res => SetServicesItems(res.data));
  }, [])

  useEffect(() => {

    if (props.feedback != null) {
      setCustomer(props.feedback.customers);
    } else {
      axios.get(Url + 'Reservation/' + startDate + '/' + endDate)
        .then(res => setReservationCustomer(res.data));
    }
  }, [])

  useEffect(() => {
    axios.get(Url + 'StatusClient')
      .then(res => StatusClient(res.data));
  }, [])

  useEffect(() => {
    setCustomerReservation(props.customerId ?? 0);
    setidReservation('1232');
    if (props.feedback != null) {
      setCustomerReservation(props.feedback.customerId);
      setComment(props.feedback.commerntarie);
      setResolution(props.feedback.solution);
      setStatus(props.feedback?.idStatus);
      setservice(props.feedback.idServicesItems);
      setIdFeedback(props.feedback.idFeedback);
    }
  }, [])

  const handleChange = async () => {

    switch (props.mode) {
      case 1:     
          try {
            const response = await  axios.post(Url + 'Feedback', {
              "customerId": customerId,
              "idReservation": idReservation,
              "commerntarie": comment,
              "idStatus": status,
              "idServicesItems": service,
              "solution": resolution
            })
              .then();
          } catch (error) {
            console.error('Hubo un error:', error);
          } finally {
            props.closeForm();
            window.location.href = `/FeedbackClient`;
          }  
        break;

      case 2:     
          try {
            const response = await axios.put(Url + 'Feedback/' + idFeedback, {
              "IdFeedback": idFeedback,
              "customerId": customerId,
              "commerntarie": comment,
              "idStatus": status,
              "idServicesItems": service,
              "solution": resolution
            })
              .then();
          } catch (error) {
            console.error('Hubo un error:', error);
          } finally {
            props.closeForm();
            window.location.href = `/feedback`;
          }   
        break;
    }
  };

  var Clients = false;  // Cambiar a false para ver el otro caso
  var EditMode = false;  // Cambiar a false para ver el otro caso

  if (props.customerId == "") {
    Clients = true;
  }
  if (props.mode == 2) {
    Clients = false;
    EditMode = true;
  }

  return (
    <div className="FeedbackForm">
      <div className="FeedbackFormTitle">{props.title}</div>
      <div className="FeedbackFormClose" onClick={props.closeForm}>X</div>
      <div className="PrimaryHeader">
        <div className="PrimaryHeaderSeparator"></div>
      </div>

      {Clients &&
        <div>
          <div className="FeedbackFormInputTitle">Client</div>
          <select className="FeedbackFormSelect" value={customerId} onChange={(e) => setCustomerReservation(e.target.value)} >
            <option value={0}> </option>
            {ReservationCustomer.map(items => (
              <option value={items.idCustomer}> {items.customers.lastName} {items.customers.firstName}</option>
            ))}
          </select>
        </div>}

      {EditMode &&
        <div>
          <div className="FeedbackFormInputTitle">Feedback - {props.feedback?.customers?.lastName}
            {props.feedback?.customers?.firstName}, chambre {props.feedback?.reservations?.number ?? "Pending.."} </div>
        </div>}

      <div className="FeedbackFormInputTitle">Commentaire</div>
      <textarea className='FeedbackFormTextarea' value={comment} onChange={(e) => setComment(e.target.value)} spellCheck={false}></textarea>
      <div className="FeedbackFormInputTitle">Status</div>
      <select className="FeedbackFormSelect" value={status} onChange={(e) => setStatus(e.target.value)}>
        <option value={0}> </option>
        {StatusFilter.map(items => (
          <option value={items.idStatus}>{items.statusName}</option>
        ))}
      </select>
      <div className="FeedbackFormInputTitle">Service</div>
      <select className="FeedbackFormSelect" value={service} onChange={(e) => setservice(e.target.value)}>
        <option value={0}> </option>
        {ServicesItemsFilter.map(items => (
          <option value={items.idServicesItems}>{items.servicesName}</option>
        ))}
      </select>
      <div className="FeedbackFormInputTitle">Résolution</div>
      <textarea className='FeedbackFormTextarea' value={resolution} onChange={(e) => setResolution(e.target.value)} spellCheck={false}></textarea>
      <div className="FeedbackFormInputTitle">Status Client</div>
      <form>
        <div>
          {StatusClientFilter.map(items => (
            <label><input type="radio" value={statusImg} id={items.statusClientName} />
              {/* <p><RadioButton value={statusImg}  onChange={(e) => setStatusImg(e.value)} variant="filled"/>  */}
              <img className="TagLogo" src={`data:image/jpeg;base64,${items.statusClientImage}`} alt="" />
            </label>
          ))}
        </div>
      </form>

      <div className="ProfilClientFormActions">
        <button className='ValiderButton' onClick={handleChange}>Valider</button>
      </div>
    </div>
  );
}

export default FeedbackForm;
