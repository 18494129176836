import { useEffect, useRef, useState } from 'react';
import GnzButton from '../shared/gnz-button/GnzButton';
import FormMode from '../../enums/FormMode';
import GnzImage from '../shared/gnz-image/GnzImage';
import plus2 from '../../images/shared/plus2.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import FeedbackTable from './FeedbackTable/FeedbackTable';
import GnzDialog from '../shared/gnz-dialog/GnzDialog';
import FeedbackForm from './FeedbackForm/FeedbackForm';
import CancelFeedbackForm from './CancelFeedbackForm/CancelFeedbackForm';
import _Feedback from '../../models/Feedback';
import GnzDrawer from '../shared/gnz-drawer/GnzDrawer';

import './Feedback.css';

function Feedback() {
  const { userName } = useSelector((state: RootState) => state.user);
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  const [startDate, setStartDate] = useState(date);
  const [endDate, setEndDate] = useState(date);
  const drawerRef = useRef<HTMLDivElement>(null);
  const cancelDialogRef = useRef<HTMLDivElement>(null);
  const [mode, setMode] = useState<FormMode>(FormMode.None);
  const [feedbackNew, setFeedbackNew] = useState<_Feedback>(new _Feedback());
  const [feedbackCancel, setFeedbackCancel] = useState<_Feedback>(new _Feedback());

  const newFeedback = () => {
    setMode(FormMode.New);
    setFeedbackNew(new _Feedback());
    openDrawer();
  };

  const openDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.open();
  }

  const closeDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.close();
  }

  const handleChange = (event: any) => {
    // axios.get(Url +'Reservation/' + startDate +'/' + event.target.value)
    // .then(res => reservation(res.data));            
  };

  return (
    <div className="feed">
      <div className="FeedbackTopActions">
        <h3 className='FeedbackTopActionsh3'>Feedback Clients</h3>

        <div className='FeedbackSelectSyleDiv'>
          {/* <input className="FeedbackDatasyle" placeholder='Du'></input>
          <input className="FeedbackDatasyle" placeholder='Au'></input> */}
          <input type="date" className="DatasyleFeddback" defaultValue={date} onChange={(e) => setStartDate(e.target.value)} />
          <input type="date" className="DatasyleFeddback" defaultValue={date} onChange={handleChange} />
          <GnzButton leftIcon={<GnzImage src={plus2} />} label="Nouveau Feedback" onClick={newFeedback}
            color="#1D62AF" backgroundColor="#A0E295" />
        </div>
      </div>

      <FeedbackTable />

      <GnzDrawer ref={drawerRef} component={
        <FeedbackForm
          mode={mode}
          feedback={feedbackNew}
          title={'Nouveau Feedback'}
          closeForm={closeDrawer}
          customerId={""}
        />
      } />
      {/* <GnzDialog ref={cancelDialogRef} component={
        <CancelFeedbackForm
          feedback={feedbackCancel}
        />}
      /> */}
    </div>
  );
}
export default Feedback;