export default class Status {
    public idStatus: number = 0;
    public statusName: string = '';

    constructor(
        idStatus: number = 0,
        statusName: string = '',
       
    ) {
        this.idStatus = idStatus;
        this.statusName = statusName;
    }
}