import { SetStateAction, useEffect, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import greenArrowLeft from '../../images/shared/greenarrowleft.png';
import greenArrowRight from '../../images/shared/greenarrowright.png';
import _FiltersProfilClient from '../../models/Tri';
import FormMode from '../../enums/FormMode';
import ProfilClientsTable from './ProfilClientsTable/ProfilClientsTable';
import { RootState } from '../../redux/store';
import GnzDrawer from '../shared/gnz-drawer/GnzDrawer';
import ProfilClientsForm from './ProfilClientsForm/ProfilClientsForm'
import Reservation from '../../models/Reservation';
import ReservationsRestau from '../../models/ReservationsRestau';
import GnzImage from '../shared/gnz-image/GnzImage';
import axios from 'axios';
import { API_MEWS_URL }   from '../../ConfigUrl';

import './ProfilClients.css';
import Customer from '../../models/Customers';

function ProfilClients() {
  const navigate = useNavigate();
  const { currentDate } = useSelector((state: RootState) => state.app);
  const [mode, setMode] = useState<FormMode>(FormMode.None);
  const drawerRef = useRef<HTMLDivElement>(null);
  const cancelDialogRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState('');
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);
  var startDate = date;
  var endDate = date;
  const [reservation, SetReservation] = useState<Array<Reservation>>([]);
  const [reservationsRestau, SetReservationsRestau] = useState<Array<ReservationsRestau>>([]);  
  const Url = API_MEWS_URL;
  const [Stato, setStato] = useState('CheckOut');
  const [TypeClient, setTypeClient] = useState('');
  const [Order, setOrder] = useState('Asc');
 
  // Función para ordenar los datos
  const sortData = (direction: string) => {
    // Ordenar los datos según la clave seleccionada
    const sortedData = [...reservation].sort((a, b) => {
      if (a.customers.lastName < b.customers.lastName) return direction === 'asc' ? -1 : 1;
      if (a.customers.lastName > b.customers.lastName) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    SetReservation(sortedData);
  };

  useEffect(() => {
    setTypeClient('Hotel');
    ClientSelect("");
  }, [])

  const profilClientsFilter = () => {
    setMode(FormMode.New);
    openDrawer();
  };

  const openDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.open();
  }

  const SelectClientDrawer = (typeClient: SetStateAction<string>) => {
    // TypeClientSelect = typeClient.toString();
    ClientSelect(typeClient);
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.close();
  }

  const closeDrawer = () => {
    const drawerRefEl: any = drawerRef.current;
    drawerRefEl.close();
  }

  const feedbackFormTitle = () => {
    switch (mode) {
      case FormMode.New:
        return 'Tous les filtres';
      default:
        return '';
    }
  }

  const feedbackFormSubmitLabel = () => {
    switch (mode) {
      case FormMode.New:
        return 'Ajouter la feedback';
      case FormMode.Edit:
        return 'Modifier la feedback';
      default:
        return '';
    }
  }

  const handleChangeDate = (event: any) => {
    startDate = event.target.value;
    ClientSelect("");
  };

  const handleChangeDateEnd = (event: any) => {
    endDate = event.target.value;
    ClientSelect("");
  };

  const ClientSelect = (typeClient: SetStateAction<string>) => {
    switch (Stato) {
      case 'CheckOut':
      default:
        GetCheckOutReservation(typeClient);
        break;
      case 'CheckIn':
        GetCheckInReservation(typeClient);
        break;

      case 'Present':
        GetCheckPresentInReservation(typeClient);
        break;
    }
  }

  const GetCheckOutReservation = (typeClient: SetStateAction<string>) => {
    SetReservation([]);
    SetReservationsRestau([]);
    setStato('CheckOut');
    var type = TypeClient;
    if (typeClient != "") {
      type = typeClient.toString();
    }

    switch (type) {
      case 'Hotel':
      default:
        setTypeClient('Hotel');
        axios.get(Url + 'Reservation/' + startDate + '/' + endDate) // POST
          .then(res => SetReservation(res.data));
        break;
      case 'Ristorante':
        setTypeClient('Ristorante');
        axios.get(Url + 'ResaRestau/GetCheckoutReservationRistorante/' + startDate + '/' + endDate) // POST
          .then(res => SetReservationsRestau(res.data));
        break;
    }
  };

  const GetCheckInReservation = (typeClient: SetStateAction<string>) => {
    SetReservation([]);
    SetReservationsRestau([]);
    setStato('CheckIn');
    var type = TypeClient;
    if (typeClient != null && typeClient != "") {
      type = typeClient.toString();
    }

    switch (type) {
      case 'Hotel':
        setTypeClient('Hotel');
        axios.get(Url + 'ResaRestau/GetCheckInReservation/' + startDate + '/' + endDate) // POST
          .then(res => SetReservation(res.data));
        break;
      case 'Ristorante':
        SetReservation([]);
        setTypeClient('Ristorante');
        axios.get(Url + 'ResaRestau/GetCheckInReservationRistorante/' + startDate + '/' + endDate) // POST
          .then(res => SetReservationsRestau(res.data));
        break;
    }
  };

  const GetCheckPresentInReservation = (typeClient: SetStateAction<string>) => {
    SetReservation([]);
    SetReservationsRestau([]);
    setStato('Present');
    var type = TypeClient;
    if (typeClient != "") {
      type = typeClient.toString();
    }
    switch (type) {
      case 'Hotel':
        setTypeClient('Hotel');
        axios.get(Url + 'Reservation/GetPresentReservation/' + startDate + '/' + endDate) // POST
          .then(res => SetReservation(res.data));
        break;
      case 'Ristorante':
        setTypeClient('Ristorante');
        axios.get(Url + 'ResaRestau/GetPresnetReservationRistorante/' + startDate + '/' + endDate) // POST
          .then(res => SetReservationsRestau(res.data));
        break;
    }
  };

  const reloadData = () => {    
    ClientSelect("");
  };  

  return (
    <div className="ProfilClients">
      <div className="ProfilClientsTopActions">
        <h3 className='ProfilClientsTopActionsh3'>Profil Clients</h3>

        <div className='inputDivSyle'>
          <input className="inputsyle" onChange={(e) => setSearch(e.target.value)} placeholder='Que recherchez-vous ?'></input>
        </div>
        <div className='selectTimeDiv'>
          <input type="date" className="Datasyle" defaultValue={startDate} onChange={handleChangeDate} />
          <input type="date" className="Datasyle" defaultValue={endDate} onChange={handleChangeDateEnd} />
        </div>
        <div className='selectSyleDiv'>

          <select className='selectSyleClient'>
            <option value={0}>tri</option>
            <option onClick={() => sortData('asc')}>   
              Acs</option>
            <option onClick={() => sortData('desc')}>            
              Desc</option>
          </select>

          <button className='' onClick={profilClientsFilter}>Filters</button>
        </div>
      </div>

      <ProfilClientsTable search={search} Reservation={reservation} ReservationsRestau={reservationsRestau} Stato={Stato} TypeClient={TypeClient} Order={Order} reloadData={reloadData} />
      <div className="Caisse">
        <div className="CaisseBottomActions">
          <div className="CaisseBottomCenterActionsBlueCircle">

            <GnzImage src={greenArrowLeft} clickable />
          </div>
          <div className={'CaisseBottomCenterActionsDotOther'} onClick={() => GetCheckOutReservation("")}></div>
          <div className={'CaisseBottomCenterActionsDotOther'} onClick={() => GetCheckInReservation("")} ></div>
          <div className={'CaisseBottomCenterActionsDotOther'} onClick={() => GetCheckPresentInReservation("")}></div>
          <div className="CaisseBottomCenterActionsBlueCircle">
            <GnzImage src={greenArrowRight} clickable />
          </div>
        </div>
      </div>
      <GnzDrawer ref={drawerRef} component={
        <ProfilClientsForm
          mode={mode}
          SelectClient={SelectClientDrawer}
          title={feedbackFormTitle()}
          closeForm={closeDrawer}
          submitLabel={feedbackFormSubmitLabel()}
          submitColor="#003671"
        />
      } />
    </div>
  );
}
export default ProfilClients;