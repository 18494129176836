import { useEffect, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import FormMode from '../../../../enums/FormMode';
import GnzDrawer from '../../../shared/gnz-drawer/GnzDrawer';
import StaysClienteTable from '../StaysClientsTable/StaysClientsTable';
import ProfilClientsCarousel from '../ProfilClientsCarousel/ProfilClientsCarousel';
import ProfilClientsTagsForm from '../ProfilClientsDetailsForm/ProfilClientsTagsForm'
import ProfilClientsEditForm from '../ProfilClientsDetailsForm/ProfilClientsEditForm'
import TagIcon from '../../../../images/ProfilClients_img/Vector.png'
import Customer from '../../../../models/Customers';
import AllergiesCustomer from '../../../../models/AllergiesCustomer';
import FoodPreferencesCustomer from '../../../../models/FoodPreferencesCustomer';
import reservation from '../../../../models/Reservation';
import axios from 'axios';
import { API_MEWS_URL }   from '../../../../ConfigUrl';
import './ProfilClientsDetailsTable.css';

interface IProfilClientsDetailsTable {
}

interface UserCivilite {
  CiviliteTxt: string;
  MandameTxt: string;
}

function ProfilClientsDetailsTable(props: IProfilClientsDetailsTable) {

  const [mode, setMode] = useState<FormMode>(FormMode.None);
  const drawerprofilClientsEdit = useRef<HTMLDivElement>(null);  
  const drawerTags = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const idReservation = localStorage.getItem('idReservation');
  const [_idReservation, SetIdReservation] = useState('');
  const idCustomer = localStorage.getItem('idCustomer');
  const [_idCustomer, SetIdCustomer] = useState('');
  const [CustomerDetails, SetCustomerDetails] = useState<Customer>();
  const Menudirect = "clientsDetails";
  const [allergiesCustomer, AllergiesCustomer] = useState<Array<AllergiesCustomer>>([]);
  const [foodPreferencesCustomer, FoodPreferencesCustomer] = useState<Array<FoodPreferencesCustomer>>([]);
  const [Reservation, SetReservation] = useState<Array<reservation>>([]);
  const tagIcon = TagIcon;
  // const [ReservationCustomer, reservationCustomer] = useState<reservation>();
  
  const Url = API_MEWS_URL;

  useEffect(() => {
    axios.get(Url + 'AllergiesCustomer/' + idCustomer)
      .then(res => AllergiesCustomer(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'FoodPreferenceCustomer/' + idCustomer)
      .then(res => FoodPreferencesCustomer(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'Reservation/' + idCustomer)
      .then(res => SetReservation(res.data));
      SetIdCustomer(idCustomer ?? "");
      SetIdReservation(idReservation ?? "");
  }, [])

  const Civilite: UserCivilite[] = [

    { CiviliteTxt: "Civilité", MandameTxt: Reservation[0]?.customers?.sex ?? "------" },
    { CiviliteTxt: "Nom", MandameTxt: Reservation[0]?.customers?.firstName ?? "------" },
    { CiviliteTxt: "Prénom", MandameTxt: Reservation[0]?.customers?.lastName ?? "------" },
    { CiviliteTxt: "Date de naissance", MandameTxt: Reservation[0]?.customers?.birthDate ?? "------" },
    { CiviliteTxt: "Adresse", MandameTxt: Reservation[0]?.customers?.customerAddresss?.line1 ?? "------" },
    { CiviliteTxt: "Code postal", MandameTxt: Reservation[0]?.customers?.nationalityCode ?? "------" },
    { CiviliteTxt: "Ville", MandameTxt: Reservation[0]?.customers?.customerAddresss?.city ?? "------" },
    { CiviliteTxt: "N° de téléphone", MandameTxt: Reservation[0]?.customers?.phone ?? "------" },
    { CiviliteTxt: "N° de téléphone 2", MandameTxt: Reservation[0]?.customers?.phone2 ?? "------" },
    { CiviliteTxt: "Email", MandameTxt: Reservation[0]?.customers?.email ?? "------" },
    { CiviliteTxt: "Email 2", MandameTxt: Reservation[0]?.customers?.email2 ?? "------" },
    { CiviliteTxt: "Nationalité", MandameTxt: Reservation[0]?.customers?.nationalityCode ?? "------" },
    { CiviliteTxt: "Langue(s)", MandameTxt: Reservation[0]?.customers?.languageCode ?? "------" },
    { CiviliteTxt: "Date importante", MandameTxt: Reservation[0]?.customers?.importantDate ?? "------" },
    { CiviliteTxt: "Heure d’arrivée", MandameTxt: Reservation[0]?.startUtc ?? "------" },
    { CiviliteTxt: "Heure de départ", MandameTxt: Reservation[0]?.endUtc ?? "------" },
    { CiviliteTxt: "CODE VIP", MandameTxt: Reservation[0]?.customers?.classificationName ?? "------" },
  ];


  const ClientTagEdit = () => {
    setMode(FormMode.New);
    openTagEdit();
  };

  const openTagEdit = () => {
    const drawerRefEl: any = drawerTags.current;
    drawerRefEl.open();
  }

  const closeTagEdit = () => {
    const drawerRefEl: any = drawerTags.current;
    drawerRefEl.close();
  }
  
  const profilClientsEdit = () => {
    SetCustomerDetails(Reservation[0]?.customers);
    setMode(FormMode.New);
    openEditClientForm();
  }; 

  const openEditClientForm = () => {
    const drawerRefEl: any = drawerprofilClientsEdit.current;
    drawerRefEl.open();
  }

  const closeEditClientForm = () => {
    const drawerRefEl: any = drawerprofilClientsEdit.current;
    drawerRefEl.close();
  }

  return (
    <div className='userDetails_payment'>
      <h3 className='userDetails_header'>Fiche Client</h3>
      <div className='userDetails_content'>
        <div className="ProfilClientsDetailsTopActions">
          <select className='selectSyle'>
            <option value={1}>Profil</option>
            {/* <option value={2}>Fiche client</option> */}           
            <option value={5} onClick={() => navigate(`/clientsPreferences`)} >Préférences</option>
            <option value={4} onClick={() => navigate(`/FeedbackClient`)} >Feedback</option>            
           <option value={3}>Email</option>
          </select>

          {/* <button className="btnstyle">Partager ficher client</button> */}

          {/* <h4 className='ProfilClientsDetailsTopActionsh3'>Prochain séjour:</h4>

          <div className='selectSyleDiv'>

            <input className="DataDetailsSyle" placeholder='Du'></input>
            <input className="DataDetailsSyle" placeholder='Au'></input>
          </div> */}

        </div>
        <div className="userDetails_info">
          <div className="userDetails_photo">
            <ProfilClientsCarousel />
          </div>
          <div className="userDetails_details">
            {Civilite.map((r) => (
              <div className="userDetails_element">
                <a onClick={profilClientsEdit}>
                  <p className='user_chambre'>{r.CiviliteTxt}</p>
                  <p className='user_nome'>{r.MandameTxt}</p>
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className="moreContent">
          <div className='TagsLine'>
                <div className='TagsName'>
                <a className="" onClick={ClientTagEdit}>  <img alt="" src={tagIcon} ></img> TAG </a>
                </div>
            {allergiesCustomer.map((r) => (
              //  <p>{r.allergies.displayAs}</p>
               <img className="TagImg" src={`data:image/jpeg;base64,${r.allergies.image}`} alt="" />    
              // <img className="TagLogo" src={`data:image/jpeg;base64,${r.tags.tagImage}`} alt="" />
            ))}
            {foodPreferencesCustomer.map((r) => (
               <img className="TagImg" src={`data:image/jpeg;base64,${r.food_Preferences.image}`} alt="" />
            ))}
          </div>
        </div>

        <GnzDrawer ref={drawerTags} component={
          <ProfilClientsTagsForm
            myParam={_idCustomer}            
            Menudirect={Menudirect}
            allergiesCustomer={allergiesCustomer}
            foodPreferencesCustomer={foodPreferencesCustomer}
            closeForm={closeTagEdit}
          />
        } />

        <GnzDrawer ref={drawerprofilClientsEdit} component={
          <ProfilClientsEditForm
            myParam={_idCustomer}
            idReservation={_idReservation}
            CustomerDetails={CustomerDetails}
            closeForm={closeEditClientForm}
          />
        } />

        <div className="moreContent">
          <div className="moreContent_style">
            <StaysClienteTable Reservation={Reservation} />
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>
  );
}

export default ProfilClientsDetailsTable