import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import GnzButton from '../../../shared/gnz-button/GnzButton';

import './ProfilClientsDetailsForm.css';

interface IProfilClientsCreditCardForm { 
  myParam: string,  
  statoName: string,
  closeForm: () => void,
  saveChanges: () => void,
  reloadData: () => void,
}

function ProfilClientsCreditCardForm(props: IProfilClientsCreditCardForm) {    
  const navigate = useNavigate();

  return (
    <div className="formCreditcard">         
      <div className="moreContent">      
       
      </div>        

      <div className="">
        <div className="">
              <p>Sei sicuro di cambiare di stato {props.statoName}</p>
        </div>
      </div>

      <div className="ProfilClientPreferenceFormActions">
        <div  className="FormActionsLefth">
          <GnzButton label="No, Cancel" onClick={props.closeForm} color="#ffffff" backgroundColor="#ee4d4d" />
        </div>
        <div  className="FormActionsRight">
          <GnzButton label="Si, Save" onClick={props.saveChanges} color="#ffffff" backgroundColor="#1D62AF" />
        </div>
      </div>

    </div>
  );
}

export default ProfilClientsCreditCardForm;
