import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Checkbox } from 'antd';
import Tag from '../../../../models/Tag';
import Allergies from '../../../../models/Allergies';
import FoodPreferences from '../../../../models/Food_Preferences';
import axios from 'axios';
import './ProfilClientsDetailsForm.css';
import AllergiesCustomer from '../../../../models/AllergiesCustomer';
import FoodPreferencesCustomer from '../../../../models/FoodPreferencesCustomer';
import GnzButton from '../../../shared/gnz-button/GnzButton';
import { API_MEWS_URL }   from '../../../../ConfigUrl';

interface IProfilClientsTagsForm { 
  myParam: string,  
  Menudirect:string,
  allergiesCustomer: Array<AllergiesCustomer>,
  foodPreferencesCustomer: Array<FoodPreferencesCustomer>,
  closeForm: () => void,
}

function ProfilClientsTagsForm(props: IProfilClientsTagsForm) {  
  const [Tag, tag] = useState<Array<Tag>>([]);
  const [Allergies, allergies] = useState<Array<Allergies>>([]);
  const [FoodPreferences, foodPreferences] = useState<Array<FoodPreferences>>([]);
  const Url = API_MEWS_URL;
   
  useEffect(() => {
    axios.get(Url + 'Tag')
      .then(res => tag(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'Allergy')
      .then(res => allergies(res.data));
  }, [])

  useEffect(() => {
    axios.get(Url + 'FoodPref')
      .then(res => foodPreferences(res.data));
  }, [])

  const handleChange = (event:any) => {     
    axios.post(Url + 'CustomerTag',{ 
      "CustomerId": props.myParam,
      "IdTag":event.target.value
    })
      .then();        
}; 

const handleChangeAllergiesCustomer = async (event:any) => {    
    try {
      const response = await  axios.post(Url + 'AllergiesCustomer',{ 
        "CustomerId": props.myParam,
        "allergiesId":event.target.value
      })
        .then();   
    } catch (error) {
      console.error('Hubo un error:', error);
    } finally {
    
    }
}; 

const handleChangeFoodPreferenceCustomer = async (event:any) => {     
    try {
      const response = await  axios.post(Url + 'FoodPreferenceCustomer',{ 
        "CustomerId": props.myParam,
        "food_PreferencesId":event.target.value
      })
        .then();   
    } catch (error) {
      console.error('Hubo un error:', error);
    } finally {
    
    }
}; 


const saveChanges = async (event:any) => {    
      window.location.reload();
}


  return (
    <div className="FeedbackForm">   
      <div  className="FormActionsLefth">
      <div className="FeedbackFormClose" onClick={props.closeForm}>X</div>
        </div>

      <div className="moreContent"> 

      <h1>Allergies</h1>

      <div className='TagsLine'>  
       
              {Allergies.map(r => (
              <p><Checkbox value={r.id} onChange={handleChangeAllergiesCustomer}  defaultChecked={ props.allergiesCustomer.some(item => r.id === item.allergiesId)}/> 
              {r.displayAs} <img className="TagImg" src={`data:image/jpeg;base64,${r.image}`} alt="" />    
              </p>
            ))}

</div>   

<h1>FoodPreferences</h1>

<div className='TagsLine'>  
       
              {FoodPreferences.map(r => (
              <p><Checkbox value={r.id} onChange={handleChangeFoodPreferenceCustomer}  defaultChecked={ props.foodPreferencesCustomer.some(item => r.id === item.food_PreferencesId)}/> 
              <img className="TagImg" src={`data:image/jpeg;base64,${r.image}`} alt="" />    
              {r.displayAs} 
              </p>
            ))}
      </div>   
      </div>         

      <div className="PrimaryHeader">
        <div className="PrimaryHeaderSeparator"></div>
      </div>

      {/* <div className="ProfilClientFormActions">
        <button className='ProfilClientButtonTout' onClick={props.closeForm}>Cancel</button>       
        <button onClick={props.closeForm}>Save</button>
      </div> */}

      <div className="ProfilClientPreferenceFormActions">      
        <div  className="FormActionsRight">
          <GnzButton label="Save" onClick={saveChanges} color="#ffffff" backgroundColor="#1D62AF" />
        </div>
      </div>

    </div>
  );
}

export default ProfilClientsTagsForm;
