import { useEffect, useRef, useState } from 'react';
import FeedbackTableItems from './FeedbackTableItems';
import Feedback from '../../../models/Feedback';
import axios from 'axios';
import { API_MEWS_URL }   from '../../../ConfigUrl';

import './FeedbackTable.css';

export default function FeedbackTable() {
  const [feedback, setFeedbacks] = useState<Array<Feedback>>([]);
  const Url = API_MEWS_URL;

  useEffect(() => {
    axios.get(Url + "Feedback/")
      .then(res => setFeedbacks(res.data));
  }, [])

  return (
    <form>

      <div className='itineraire_table'>
        <h3 className='booking_header'>Fiche Client</h3>
        <div className='userItineraireItineraire_content'>

          <div>
            <div className='divFeedBack2'>
              <td className='tableFeedback_title'>Nom</td>
              <td className='tableFeedback_title'>Chambre</td>
              <td className='tableFeedback_title'>Service </td>
              <td className='feedbackStyle'></td>
            </div>
          </div>

          {feedback.map((feedback, index) => (
            <div>
              <div className='divFeedBack2'>
                <div className='headertrFeedbackSelect'>
                  <td className='tableFeedback_data'>{feedback.customers.lastName} {feedback.customers.firstName}</td>
                  <td className='tableFeedback_data'>{feedback.reservations?.number ?? "Pending.."}</td>
                  <td className='tableFeedback_data'>{feedback.servicesItems.servicesName}</td>
                </div>
                <div >
                  <FeedbackTableItems feedback={feedback} />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
        </div>
      </div>
    </form>
  );
}