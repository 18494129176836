import { useEffect, useState } from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import axios from 'axios';
import './ProfilClientsDetailsForm.css';
import CustomerDetails from '../../../../models/CustomerDetails';
import GnzButton from '../../../shared/gnz-button/GnzButton';
import { API_MEWS_URL }   from '../../../../ConfigUrl';

interface IProfilClientsEditForm {
  myParam: string,
  idReservation: string,
  CustomerDetails?: CustomerDetails,
  closeForm: () => void,
}

function ProfilClientsEditForm(props: IProfilClientsEditForm) {
  const [sex, setSex] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [nationalityCode, setPostalCode] = useState('');
  const [email, setEmail1] = useState('');
  const [phone, setPhone1] = useState('');   
  const Url = API_MEWS_URL;

  useEffect(() => {
    setSex(props.CustomerDetails?.sex ?? "------");
    setFirstName(props.CustomerDetails?.firstName ?? "------");
    setlastName(props.CustomerDetails?.lastName ?? "------");
    setBirthDate(props.CustomerDetails?.birthDate ?? "------");
    // setBirthDate(props.CustomerDetails?.city ?? "------");
    setPostalCode(props.CustomerDetails?.nationalityCode ?? "------");
    setEmail1(props.CustomerDetails?.email ?? "------");
    setPhone1(props.CustomerDetails?.phone ?? "------");
  }, [])

  const handleChange = async () => {
    try {
      const response = await axios.put(Url + 'Customers/'+ props.myParam, {
        "customersId": props.myParam,
        "sex": sex,
        "firstName": firstName,
        "lastName": lastName,
        "birthDate":  birthDate,
        "nationalityCode": nationalityCode,
        "languageCode": "Français",
        "birthPlace": "2 rue du park",
        "email": email,
        "phone": phone
      })
        .then();
    } catch (error) {
      console.error('Hubo un error:', error);
    } finally {
      props.closeForm();      
        window.location.reload();   
    }  
  };

  return (
    <div className="FeedbackForm">
      <div className="moreContent">
        <DialogTitle id="responsive-dialog-title">
          Modification Fiche client
        </DialogTitle>
        <div className="moreContent">
          {/* {props.customer.map((r , index) => ( 
              <div>
                <h5 className="CancelConsigneFormLabel">{r.CiviliteTxt}</h5>
                <input type="text" placeholder="Civilté" className="AppFormInput" 
                value={r.MandameTxt} onChange={(e) => setTitle(e.target.value)} spellCheck={false}></input>              
              </div>
            ))}      */}

          <h5 className="CancelConsigneFormLabel">Civility</h5>
          <input type="text" placeholder="Civility" className="AppFormInput" value={sex}
            onChange={(e) => setSex(e.target.value)} spellCheck={false}></input>

          <h5 className="CancelConsigneFormLabel">Nom</h5>
          <input type="text" placeholder="Nome" className="AppFormInput" value={firstName}
            onChange={(e) => setFirstName(e.target.value)} spellCheck={false}></input>

          <h5 className="CancelConsigneFormLabel">Prénom</h5>
          <input type="text" placeholder="Nome" className="AppFormInput" value={lastName}
            onChange={(e) => setlastName(e.target.value)} spellCheck={false}></input>

          <h5 className="CancelConsigneFormLabel">birthDate</h5>
          <input type="date" placeholder="Nome" className="AppFormInput" value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)} spellCheck={false}></input>

          <h5 className="CancelConsigneFormLabel">postalCode</h5>
          <input type="text" placeholder="Nome" className="AppFormInput" value={nationalityCode}
            onChange={(e) => setPostalCode(e.target.value)} spellCheck={false}></input>

          <h5 className="CancelConsigneFormLabel">email1</h5>
          <input type="text" placeholder="Nome" className="AppFormInput" value={email}
            onChange={(e) => setEmail1(e.target.value)} spellCheck={false}></input>

          <h5 className="CancelConsigneFormLabel">phone1</h5>
          <input type="text" placeholder="Nome" className="AppFormInput" value={phone}
            onChange={(e) => setPhone1(e.target.value)} spellCheck={false}></input>
        </div>
        <DialogActions>
          <GnzButton label="Annuler" onClick={props.closeForm} backgroundColor="#dc3545"/>
          <GnzButton label="Confirmer" onClick={handleChange}  />        
        </DialogActions>
      </div>
    </div>
  );
}
export default ProfilClientsEditForm;