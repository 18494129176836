import { useNavigate } from 'react-router-dom';
import reservation from '../../../../models/Reservation';

import './StaysClientsTable.css';

interface IStaysClientsTable { 
    Reservation: Array<reservation>,
  }

function StaysClientsTable(props: IStaysClientsTable) {

    const navigate = useNavigate();   

    return (
        <div className='booking_table'>
            <h3 className='userStays_header'>Séjours</h3>
            <div className='userStays_content'>
                <div className=''>
                    <div className="userStays_element">
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <td className='table_thead'>Arrivée</td>
                                        <td className='table_thead'>Départ</td>
                                        <td className='table_thead'>Room</td>
                                        <td className='table_thead'></td>
                                    </tr>
                                </thead>
                                {props.Reservation.map((r, index) => (
                                    <tbody>
                                        <tr>
                                            <td className='table_data'>{r.startUtc}</td>
                                            <td className='table_data'>{r.endUtc}</td>
                                            <td className='table_data'>{r.number}</td>
                                            <td className='table_data link' onClick={() => navigate(`/clientsItineraire`)}>...</td>
                                        </tr>
                                    </tbody>
                                ))}

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StaysClientsTable