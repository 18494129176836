import Itineraire from '../../../../models/Itineraire';
import { useNavigate } from 'react-router-dom';
import HistoriClientsItems from './HistoriClientsItems';

import './../../ItineraireClients/ItineraireClients';

import '../../ItineraireClients/ItineraireClients.css';
import Item from 'antd/es/list/Item';

interface IHistoriClientsTable {
   
}

interface HistoriClientsTable {
    itemTitle: string;
    itemTime: string;
}

interface HistoriClientsTableItems {
    itemData: string;
    imgItem: string;
    itemTime: string;
}

function HistoriClientsTable(props: IHistoriClientsTable) {
    const navigate = useNavigate();
    const _infoItineraireItems: HistoriClientsTableItems[] = [
        { imgItem: "Icon",itemData: "10/08/2022", itemTime: "10h00" },
        { imgItem: "Icon",itemData: "10/08/2022", itemTime: "10h00" },
        { imgItem: "Icon",itemData: "10/08/2022", itemTime: "10h00" }
    ];
    const _infoItineraire: HistoriClientsTable[] = [
        { itemTitle: "edit this", itemTime: "edit this" },
        { itemTitle: "edit this", itemTime: "edit this" },
        { itemTitle: "edit this", itemTime: "edit this" }
    ];

    return (
        <div>
            {_infoItineraireItems.map((r, index) => (

                <div>

                    {/* <div className='div1'>
                        <td className=''><input type="checkbox" readOnly={true} /></td>
                    </div> */}

                    <div className='SelectItinerarie'>
                        <div className='headertrSelect'>
                        <td className=''><input type="checkbox" readOnly={true} /></td>
                            <td className='tableItinereire_data'> {r.imgItem}</td>
                            <td className='tableItinereire_data'> {r.itemData}</td>
                            <td className='tableItinereire_data'> {r.itemTime}</td>

                        </div>
                        <div >
                            <HistoriClientsItems />
                        </div>

                    </div>

                </div>

            ))}
        </div>
    );
}
export default HistoriClientsTable