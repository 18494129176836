import Customers from "./Customers";

export default class Reservation {
    public id: string = '';
    public idReservation: string = '';
    public idCustomer: string = '';
    public state: string = '';
    public number: string = '';
    public startUtc: any = new Date();
    public endUtc: any = new Date();
    public startUtcDate: any = new Date();
    public endUtcDate: any = new Date();
    public customers: Customers = new Customers();

    constructor(
        id: string = '',
        idReservation: string = '',
        idCustomer: string = '',
        state: string = '',
        number: string = '',
        startUtc: any = '',
        endUtc: any = '',
        startUtcDate: any = '',
        endUtcDate: any = '',
        customers: Customers = new Customers(),
        
    ) {
        this.id = id;
        this.idReservation = idReservation;
        this.idCustomer = idCustomer;
        this.number = number;
        this.state = state;
        this.startUtc = startUtc;
        this.endUtc = endUtc; 
        this.startUtcDate = startUtcDate;  
        this.endUtcDate = endUtcDate;  
        this.customers = customers;     
    }
}