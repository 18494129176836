import { useEffect, useState } from 'react';
import { DialogContent, DialogTitle } from '@mui/material';
import Feedback from '../../../models/Feedback';
import GnzButton from '../../shared/gnz-button/GnzButton';
import axios from 'axios';
import { API_MEWS_URL }   from '../../../ConfigUrl';
import './CancelFeedbackForm.css';


interface ICancelFeedbackForm {
  feedback: Feedback,
  closeAnulerDrawer: () => void
}

function CancelFeedbackForm(props: ICancelFeedbackForm) {
  const [reason, setReason] = useState('');
  const [idFeedback, setIdFeedback] = useState('');
  const Url = API_MEWS_URL;


  useEffect(() => {
    if (props.feedback != null) {
      setIdFeedback(props.feedback.idFeedback);
    }
  }, [])

  const confirmSubmit = async () => {
    if (reason.length) {
      try {
        const response = await axios.put(Url + 'Feedback/AnnularFeedback/' + idFeedback, {
          "idFeedback": idFeedback,
          "reasonAnnulled": reason
        })
          .then();
      } catch (error) {
        console.error('Hubo un error:', error);
      } finally {
        props.closeAnulerDrawer();
        window.location.href = `/feedback`;
      }
    }
  }

  return (
    <div className="formAnullerFeed">
      <div className="FeedbackFormClose" onClick={props.closeAnulerDrawer}>X</div>
      <DialogTitle id="responsive-dialog-title">
        Raison d’annulation:
      </DialogTitle>
      <DialogContent>
        <div className='DivInputStyle'>
          <input className="InputStyle" type="text" value={reason} onChange={(e) => setReason(e.target.value)} spellCheck={true}></input>
        </div>
      </DialogContent>

      <div className="FormActionsCenter">
        <GnzButton label="Valider" onClick={confirmSubmit} color="#ffffff" backgroundColor="#1D62AF" />
      </div>
    </div>
  );
}

export default CancelFeedbackForm;