export default class CustomerAddresss {
    public addressId: string = '';
    public idCustomer: string = '';
    public line1: string = '';
    public line2: string = '';  
    public city: string = '';
    public postalCode: string = '';
    public countryCode: string = '';   

    constructor(
      
        addressId: string = '',
        idCustomer: string = '',
        line1: string = '',
        line2: string = '',
        city: string = '',
        postalCode: string = '',
        countryCode: string = '',

    ) {
        this.addressId = addressId;
        this.idCustomer = idCustomer;
        this.line1 = line1;
        this.line2 = line2;
        this.city = city;
        this.postalCode = postalCode;
        this.postalCode = postalCode;
        this.countryCode = countryCode;       
    }
}